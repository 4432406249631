$light-primary-text: white;
$dark-primary-text: rgba(black, 0.87);
$dark-secondary-text: #3c4150;
$white-background: #fafafa;

$swiss-re-green: (
  500: #00aa91,
  800: #007934,
  900: #627d77,
  contrast: (
    500: $dark-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
  ),
);

$swiss-re-grey: (
  50: #ececee,
  contrast: (
    50: $dark-primary-text,
  ),
);

$swiss-re-yellow: (
  200: #ffd700,
  500: #ce8d2c,
  contrast: (
    500: $light-primary-text,
  ),
);

$swiss-re-blue: (
  deep-blue-8: #e4e9f3,
  deep-blue-15: #dce6f5,
  deep-blue-25: #e8eef7,
  deep-blue-50: #8aaada,
  deep-blue-75: #4780c7,
  deep-blue-100: #1455b4,
  // default
  contrast:
    (
      deep-blue-8: $dark-primary-text,
      deep-blue-15: $dark-primary-text,
      deep-blue-25: $dark-primary-text,
      deep-blue-50: $dark-primary-text,
      deep-blue-75: $light-primary-text,
      deep-blue-100: $light-primary-text,
    ),
);

$swiss-re-red: (
  50: #ffebee,
  100: #ffcdd2,
  200: #ef9a9a,
  300: #e57373,
  400: #ef5350,
  500: #f44336,
  600: #e53935,
  700: #d32f2f,
  800: #c62828,
  900: #b32028,
  A100: #ff8a80,
  A200: #ff5252,
  A400: #ff1744,
  A700: #e00034,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $light-primary-text,
    A400: $light-primary-text,
    A700: $light-primary-text,
  ),
);

$swiss-re-pink: (
  50: #fce4ec,
  100: #f8bbd0,
  200: #f48fb1,
  300: #f06292,
  400: #ec407a,
  500: #e91e63,
  600: #d81b60,
  700: #c2185b,
  800: #ad1457,
  900: #880e4f,
  A100: #ff80ab,
  A200: #ff4081,
  A400: #f50057,
  A700: #c51162,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $light-primary-text,
    A400: $light-primary-text,
    A700: $light-primary-text,
  ),
);
