@use '@angular/material' as mat;
@use '@material/density/density';
@use 'sass:map';
@use '../theme';
@use '../utils/utils';
@use '../variables';

$height: 32px;
$minimum-height: 24px;
$maximum-height: $height;
$density-scale: density.$default-scale;

$density-config: (
  height: (
    default: $height,
    maximum: $maximum-height,
    minimum: $minimum-height,
  ),
);

@mixin theme() {
  $color-config: mat.get-color-config(theme.$theme-config);
  $primary: map-get($color-config, primary);

  @include mat.chips-theme(theme.$theme-config);

  mat-chip-listbox {
    mat-chip-option.mat-mdc-standard-chip:not(.mdc-evolution-chip--disabled) {
      &.blue {
        .mdc-evolution-chip__text-label,
        .mdc-evolution-chip__icon--trailing {
          color: mat.get-color-from-palette($primary, deep-blue-100);
        }
        background-color: mat.get-color-from-palette($primary, deep-blue-15);
      }
    }
  }

  mat-chip.mat-mdc-chip {
    &.black {
      $black-color: mat.get-contrast-color-from-palette($primary, deep-blue-8);
      .mdc-evolution-chip__text-label,
      .mdc-evolution-chip__icon--trailing {
        color: $black-color;
      }
      border: 1px solid $black-color;
      background-color: mat.get-contrast-color-from-palette($primary, deep-blue-100);
      $height-config: map.get($density-config, height);
      height: utils.clamp-density($density-scale, map.get($height-config, minimum), map.get($height-config, maximum));
    }

    &.effective {
      background-color: map-get(variables.$rating-status, effective);

      .mdc-evolution-chip__text-label {
        color: mat.get-contrast-color-from-palette($primary, deep-blue-100);
      }
    }

    &.notEffective {
      background-color: map-get(variables.$rating-status, notEffective);

      .mdc-evolution-chip__text-label {
        color: mat.get-contrast-color-from-palette($primary, deep-blue-100);
      }
    }

    &.notAssessed {
      background-color: map-get(variables.$rating-status, notAssessed);

      .mdc-evolution-chip__text-label {
        color: mat.get-contrast-color-from-palette($primary, deep-blue-100);
      }
    }

    &.notApplicable {
      background-color: map-get(variables.$rating-status, notApplicable);

      .mdc-evolution-chip__text-label {
        color: mat.get-contrast-color-from-palette($primary, deep-blue-100);
      }
    }
  }
}
