@mixin styles() {
  mat-expansion-panel.mat-expansion-panel {
    border-radius: 13px;

    .mat-content mat-panel-title.mat-expansion-panel-header-title {
      margin-right: 16px;
      white-space: nowrap;
      flex: 60%;
    }

    mat-expansion-panel-header {
      .mat-expansion-panel-header-title {
        mat-radio-button,
        mat-checkbox {
          margin: 0 1rem;
        }
      }
    }
  }
}
