@mixin faces() {
  @font-face {
    font-family: SwissReSanBold;
    src: url(../fonts/SwissReSan-Bold.woff2) format("opentype");
  }

  @font-face {
    font-family: SwissReSanBoldItal;
    src: url(../fonts/SwissReSan-BoldItal.woff2) format("opentype");
  }

  @font-face {
    font-family: SwissReSanItal;
    src: url(../fonts/SwissReSan-Ital.woff2) format("opentype");
  }

  @font-face {
    font-family: SwissReSans Light;
    src: url(../fonts/SwissReSan-Ligh.woff2) format("opentype");
  }

  @font-face {
    font-family: SwissReSanLighItal;
    src: url(../fonts/SwissReSan-LighItal.woff2) format("opentype");
  }

  @font-face {
    font-family: SwissReSanRegu;
    src: url(../fonts/SwissReSan-Regu.woff2) format("opentype");
  }
}
