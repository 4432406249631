@mixin styles() {
  app-table {
    &:not(.hoverless) {
      .row:hover {
        box-sizing: border-box;
        box-shadow: inset 5px 0 0 0;
        cursor: pointer;
      }
    }

    &.borderless > p-table > .p-datatable {
      border: none;
    }
  }

  .p-datatable .p-datatable-tbody > tr > td:not(.word-break-inherit) {
    word-break: normal;
    overflow-wrap: break-word;
  }

  .p-datatable .p-datatable-tbody > tr > td.align-right {
    text-align: right;
  }

  .p-datatable .p-datatable-tbody > tr > td.align-center {
    text-align: center;
  }

  .p-datatable .p-datatable-thead > tr > th {
    font-weight: 700;

    &.flex {
      display: flex;
      align-items: center;
    }
  }
}
