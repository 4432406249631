.show-more {
  overflow: hidden;
  height: min-content;
  line-height: var(--line-height);
}

:host {
  --line-height: 1;
}

.show-more-container {
  position: relative;
  height: min-content;
}

.show-more.expand {
  max-height: none !important;
}

.show-more.expand::before {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: white;
}

.show-more-text::after {
  cursor: pointer;
  line-height: 1;
  text-align: left;
  display: block;
  position: absolute;
  bottom: -20px;
  left: 0;
  width: 100%;
  height: var(--line-height);
  background: rgb(255, 255, 255);
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0) 100%
  );
}
