@use '@angular/material' as mat;
@use '../theme';

@mixin theme() {
  $color-config: mat.get-color-config(theme.$theme-config);
  $primary: map-get($color-config, primary);

  @include mat.button-theme(theme.$theme-config);

  .mdc-button.mdc-button--outlined.mat-mdc-outlined-button.mat-primary {
    border-color: mat.get-color-from-palette($primary, deep-blue-100);
  }
}
