@use '@angular/material' as mat;
@use '../theme';

@mixin base() {
  mat-tab-nav-panel.flex {
    display: flex;
    flex-direction: column;

    &.grow {
      flex-grow: 1;
    }
  }
}

@mixin theme() {
  @include mat.tabs-theme(theme.$theme-config);
  @include base();
}
