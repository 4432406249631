@use 'sass:math';

@mixin styles() {
  .swiss-re-container {
    $spacing: 16px;

    display: block;
    padding: $spacing $spacing 0 $spacing;

    &.small {
      padding: math.div($spacing, 2) 0 0 0;
    }

    &.no-top-spacing {
      padding: 0 $spacing;
    }

    &.small-top-spacing {
      padding-top: math.div($spacing, 2);
    }
  }

  .swiss-re-flex-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
}
