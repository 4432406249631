.black-87 {
  color: $SR_Black_900-87P !important;
}

.black-60 {
  color: $SR_Black_900-60P !important;
}

.Mid_Blue {
  color: $SR_Mid_Blue !important;
}

.notApplicable {
  white-space: nowrap !important;
  color: $primaryButtonInactiveColor !important;
}
