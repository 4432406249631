// TODO: Remove once all classes will be properly aligned in theme / typo config
.warning-txt {
  color: red !important;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.nowrap-text {
  white-space: nowrap !important;
}

.breack-word {
  white-space: pre-line !important;
}

.showMoreLess {
  font-size: 14px !important;
  text-align: right;
  font-weight: 700 !important;
  cursor: pointer !important;
}

.read-More-Style {
  color: $readMoreColor !important;
}

.normal-white-space {
  white-space: normal !important;
}

.modal-opener {
  font-weight: 400;
  color: $SR_Mid_Blue;
  text-decoration: none;
  cursor: pointer;
  white-space: nowrap;
}

.text-bold {
  font-weight: bold !important;
}

.text-bolder {
  font-weight: bolder !important;
}

.text-underline:hover {
  text-decoration: underline;
  text-decoration-color: inherit;
  text-decoration-thickness: 2px;
  text-underline-offset: 4px;
}

.title {
  padding: 0 24px;
}
