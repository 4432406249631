@use '@angular/material' as mat;
@use '../theme';
@use '../palette';
@use './icon-base';

@mixin theme() {
  @include mat.icon-theme(theme.$theme-config);

  .mat-icon.warn {
    color: mat.get-color-from-palette(palette.$swiss-re-red, 500);
  }

  .mat-icon.success {
    color: mat.get-color-from-palette(palette.$swiss-re-green, 800);
  }

  .mat-icon.small {
    @include icon-base.size(small);
  }
}
