@use '../../../styles/variables';

@mixin status-texts() {
  .sr-positive-text {
    color: map-get(variables.$status-colors, positive);
  }

  .sr-negative-text {
    color: map-get(variables.$status-colors, negative);
  }

  .sr-error-text {
    color: map-get(variables.$status-colors, error);
  }

  .sr-highlight-text {
    color: map-get(variables.$status-colors, highlight);
  }

  // TODO: Temporary solution, add Gray color to theme with merging solution
  .sr-secondary-text {
    color: #636773;
  }
}
