@use '@angular/material' as mat;
@use '../theme';
@use '../variables' as variables;

@mixin selection($primary) {
  &.selectable:hover,
  &.hover-able:hover {
    cursor: pointer;
    background-color: mat.get-color-from-palette($primary, deep-blue-8);
    transition: background-color 250ms ease-in-out 100ms;
  }

  &.selected {
    border: 2px solid mat.get-color-from-palette($primary, deep-blue-100);
    background-color: mat.get-color-from-palette($primary, deep-blue-8);
  }
}

@mixin theme() {
  @include mat.card-theme(theme.$theme-config);
  $color-config: mat.get-color-config(theme.$theme-config);
  $primary: map-get($color-config, primary);
  $foreground: map-get($color-config, foreground);

  mat-card-actions.border-top {
    border-top: 1px solid variables.$borderColor;
  }

  mat-card.with-border {
    border: 1px solid mat.get-color-from-palette($foreground, disabled);
    border-radius: 1rem;
  }

  mat-card,
  app-selectable-card mat-card {
    @include selection($primary);
  }
}
