// tobBar
.material-icons {
  font-family: 'Material Icons', Helvetica, sans-serif !important;
}

app-right-sidenav {
  height: 100%;
}

mark,
.mark {
  padding: 0em !important;
  background-color: yellow !important;
  height: 1.75em;
}

.ui-tree .ui-tree-container {
  white-space: pre-wrap;
}

.p-datatable .p-datatable-tbody > tr > td:not(.word-break-inherit) {
  word-break: normal;
  overflow-wrap: anywhere;
}
