@use '@angular/material' as mat;
@use '../palette';
@use '../theme';

@mixin theme() {
  $color-config: mat.get-color-config(theme.$theme-config);
  $primary: map-get($color-config, primary);

  .p-datatable .p-sortable-column.p-highlight .p-sortable-column-icon {
    color: mat.get-color-from-palette($primary, deep-blue-100);
  }

  app-table:not(.hoverless) .p-datatable .p-datatable-tbody > tr.row:hover,
  .p-datatable .p-datatable-tbody > tr.hovered-row {
    cursor: pointer;
    background-color: palette.$white-background;
    box-shadow: inset 5px 0 0 0 mat.get-color-from-palette($primary, deep-blue-100);
  }

  .p-datatable .p-datatable-tbody > tr > td.link {
    color: mat.get-color-from-palette($primary, deep-blue-100);
    cursor: pointer;
  }
}
