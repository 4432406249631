@use '@angular/material' as mat;
@use '../theme';
@use '../variables' as variables;

@mixin theme() {
  @include mat.dialog-theme(theme.$theme-config);
  $color-config: mat.get-color-config(theme.$theme-config);
  $primary: map-get($color-config, primary);

  mat-dialog-container .mdc-dialog__container .mat-mdc-dialog-surface {
    background-color: #fafafa; // FIXME: should go from theme

    .mat-mdc-dialog-actions {
      border-top: 1px solid variables.$borderColor;
    }

    .mat-mdc-dialog-title h2 {
      color: mat.get-color-from-palette($primary, deep-blue-100);
    }
  }
}
