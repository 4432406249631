@use '@angular/material' as mat;
@use '../theme';

@mixin theme() {
  @include mat.toolbar-theme(theme.$theme-config);

  mat-toolbar.mat-toolbar.mat-toolbar-single-row {
    padding: 0 0.5rem 0 0;

    &.transparent {
      background-color: transparent;
      box-shadow: none;
    }
  }
}
