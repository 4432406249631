@use '@angular/material' as mat;
@use '../theme';

@mixin theme() {
  $color-config: mat.get-color-config(theme.$theme-config);
  $typography-config: mat.get-typography-config(theme.$theme-config);
  $primary: map-get($color-config, primary);
  $blue-color: mat.get-color-from-palette($primary, deep-blue-100);

  @include mat.button-toggle-theme(theme.$theme-config);

  mat-button-toggle.mat-button-toggle-appearance-standard {
    color: $blue-color;
    border-color: $blue-color;

    &.mat-button-toggle-checked {
      background-color: mat.get-color-from-palette($primary, deep-blue-15);
      color: $blue-color;
      border-color: $blue-color;
    }
  }

  mat-button-toggle-group.mat-button-toggle-group-appearance-standard.mat-button-toggle-group {
    border-color: $blue-color;

    mat-button-toggle.mat-button-toggle {
      @include mat.typography-level($typography-config, body-2);

      &:not(:last-of-type) {
        border-right: 1px solid $blue-color;
      }
    }

    &.rounded {
      @include mat.button-toggle-density(-2);
    }
  }
}
