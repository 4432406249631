@mixin styles() {
  .mat-mdc-tab-nav-bar.mat-mdc-tab-header {
    $horizontal-padding: 1rem;
    margin-left: -$horizontal-padding;
    margin-right: -$horizontal-padding;
  }

  // This is current only case on Home Screen, if some new case will happen find better solution
  mat-card {
    &.no-padding {
      mat-card-header {
        padding: 0;
      }
      mat-card-content {
        padding: 0;
      }
    }

    &.home-tabs-full-height {
      $button-space-with-margin: 52px;
      height: calc(100% - $button-space-with-margin);
      overflow: hidden;
    }

    &.selectable {
      box-sizing: content-box;

      mat-card-header {
        align-items: center;
      }
    }

    &.full-height {
      height: 100%;
      overflow-y: auto;
    }

    mat-card-content.mat-mdc-card-content.flex {
      display: flex;
      flex-direction: column;
      flex-grow: 1;

      &:has(app-table) {
        padding-bottom: 0;
      }
    }
  }
}
