@use '@angular/material' as mat;
@use '../theme';

@mixin color($color-config) {
  $primary: map-get($color-config, primary);
  $primary-blue: mat.get-color-from-palette($primary, deep-blue-100);

  mat-slide-toggle.mat-primary {
    --mdc-switch-selected-focus-state-layer-color: #{$primary-blue};
    --mdc-switch-selected-handle-color: #{$primary-blue};
    --mdc-switch-selected-hover-state-layer-color: #{$primary-blue};
    --mdc-switch-selected-pressed-state-layer-color: #{$primary-blue};
    --mdc-switch-selected-focus-handle-color: #{$primary-blue};
    --mdc-switch-selected-hover-handle-color: #{$primary-blue};
    --mdc-switch-selected-pressed-handle-color: #{$primary-blue};
    --mdc-switch-selected-focus-track-color: #{$primary-blue};
    --mdc-switch-selected-hover-track-color: #{$primary-blue};
    --mdc-switch-selected-pressed-track-color: #{$primary-blue};
    --mdc-switch-selected-track-color: #{$primary-blue};
  }
}

@mixin base() {
  mat-slide-toggle.full-width {
    .mdc-form-field.mat-internal-form-field.mdc-form-field--align-end {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .mdc-form-field--align-end > label {
      margin-left: unset;
    }
  }
}

@mixin theme() {
  $color-config: mat.get-color-config(theme.$theme-config);
  @include mat.slide-toggle-theme(theme.$theme-config);
  @include color($color-config);

  @include base();
}
