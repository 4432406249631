@use '@angular/material' as mat;
@use '../theme';

@mixin theme() {
  $color-config: mat.get-color-config(theme.$theme-config);
  $primary: map-get($color-config, primary);
  $foreground: map-get($color-config, foreground);

  @include mat.datepicker-theme(theme.$theme-config); // TODO: Move to datepicker theme

  @include mat.form-field-theme(theme.$theme-config);

  @include mat.form-field-density(-1);

  mat-form-field.mat-mdc-form-field:not(.mat-form-field-invalid):not(.mat-form-field-appearance-outline):not(.gray)
    .mdc-text-field:not(.mdc-text-field--disabled)
    .mdc-floating-label {
    color: mat.get-color-from-palette($primary, deep-blue-100);
  }

  mat-form-field mat-label,
  mat-form-field .mat-mdc-form-field-required-marker {
    @extend .sr-secondary-text;
  }

  mat-form-field:has(input[readonly]),
  mat-form-field:has(textarea[readonly]) {
    .mdc-text-field--filled:not(.mdc-text-field--disabled) {
      border-left: 1px solid mat.get-color-from-palette($foreground, disabled);
      background-color: unset;
    }

    div[matformfieldlineripple] {
      display: none;
    }
  }
}
