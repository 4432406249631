@use '@angular/material' as mat;
@use './palette';
@use './theme';

$colors: (
  status: (
    negative: mat.get-color-from-palette(palette.$swiss-re-red, 900),
    error: mat.get-color-from-palette(palette.$swiss-re-red, 500),
    positive: #00aa91,
    highlight: #ce8d2c,
  ),
);

$status-colors: map-get($colors, status);

$SR_Mid_Blue: mat.get-color-from-palette(palette.$swiss-re-blue, deep-blue-100);
$SR_Gray_Slate: #3c4150;
$SR_Black_900-87P: rgba(0, 0, 0, 0.87); // Primary dark color
$SR_Black_900-60P: rgba(0, 0, 0, 0.6);
$SR_Black_900-12P: rgba(0, 0, 0, 0.12);
$SR_TextColor: #3c4150;
$primaryButtonInactiveColor: #8b8b8b;
$borderColor: #d9d9d9;
$readMoreColor: $SR_Gray_Slate;
$materialDesignWhite200: #f5f5f5;

$rating-status: (
  effective: map-get($status-colors, positive),
  notEffective: map-get($status-colors, negative),
  notAssessed: map-get($status-colors, highlight),
  notApplicable: $SR_Black_900-87P,
);
