// Deprecated - Do NOT use in new components

.flex-centerX-centerY {
  display: -ms-flexbox !important;
  display: flex !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -ms-flex-align: center !important;
  align-items: center !important;
}

.flex-centerX-endY {
  display: -ms-flexbox !important;
  display: flex !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -ms-flex-align: end !important;
  align-items: flex-end !important;
}

.flex-startX-centerY {
  display: -ms-flexbox !important;
  display: flex !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -ms-flex-align: center !important;
  align-items: center !important;
}

.flex-endX-startY {
  display: -ms-flexbox !important;
  display: flex !important;
  -ms-flex-pack: end !important;
  justify-content: flex-end !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
}

.flex-startX-startY {
  display: -ms-flexbox !important;
  display: flex !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
}

.flex-centerX {
  display: -ms-flexbox !important;
  display: flex !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
}

.flex-centerY {
  display: -ms-flexbox !important;
  display: flex !important;
  -ms-flex-align: center !important;
  align-items: center !important;
}

.flex-center {
  display: -ms-flexbox !important;
  display: flex !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  justify-content: center !important;
}

.flex-endX {
  display: -ms-flexbox !important;
  display: flex !important;
  -ms-flex-pack: end !important;
  justify-content: flex-end !important;
}

.flex-column {
  -ms-flex-direction: column !important;
  flex-direction: column !important;
}

.flex-row {
  -ms-flex-direction: row !important;
  flex-direction: row !important;
}

.d-flex {
  display: -ms-flexbox !important;
  display: flex !important;
}

.flex-grow-1 {
  flex-grow: 1;
}

.d-block {
  display: block !important;
}

.w-15 {
  width: 15% !important;
}

.w-25 {
  width: 25% !important;
}

.w-30 {
  width: 30% !important;
}

.w-50 {
  width: 50% !important;
}

.w-65 {
  width: 65% !important;
}

.w-75 {
  width: 75% !important;
}

.w-85 {
  width: 85% !important;
}

.w-100 {
  width: 100% !important;
}
