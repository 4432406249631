@mixin styles() {
  mat-vertical-stepper {
    mat-step.mat-step.disabled {
      pointer-events: none;
    }

    &.empty-sub-stepper {
      display: none;
    }
    &.sub-stepper .mat-step {
      .mat-vertical-content-container {
        display: none;
      }

      .mat-step-header {
        padding: 0.5rem;

        .mat-step-icon {
          display: none;
        }
      }
    }
  }

  mat-stepper.mat-stepper-horizontal.disabled .mat-step-header.mat-horizontal-stepper-header {
    pointer-events: none;
  }
}
