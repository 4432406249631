@use '@angular/material' as mat;
@use '../theme';
@use '../palette';

@mixin theme() {
  $color-config: mat.get-color-config(theme.$theme-config);
  $primary: map-get($color-config, primary);

  @include mat.snack-bar-theme(theme.$theme-config);

  .mat-mdc-snack-bar-container {
    &.success-snackbar .mdc-snackbar__surface {
      background-color: mat.get-color-from-palette(palette.$swiss-re-green, 800);

      .mat-mdc-snack-bar-actions .mat-mdc-button.mat-mdc-snack-bar-action:not(:disabled) {
        color: mat.get-color-from-palette(palette.$swiss-re-yellow, 200);
      }
    }

    &:not(.success-snackbar) {
      .mat-mdc-snack-bar-actions .mat-mdc-button.mat-mdc-snack-bar-action:not(:disabled) {
        color: mat.get-color-from-palette($primary, deep-blue-100);
      }
    }
  }
}
