@use '@angular/material' as mat;
@use '../theme';

@mixin base() {
  .mdc-tooltip {
    white-space: pre-line;
  }
}

@mixin theme() {
  @include mat.tooltip-theme(theme.$theme-config);
  @include base();
}
