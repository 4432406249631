.text-input-highlight-container {
  position: relative;

  .text-input-element {
    background: none;
    position: relative;
    z-index: 2;
  }

  .text-highlight-element {
    word-break: break-word;
    white-space: pre-wrap;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    color: rgba(0, 0, 0, 0);
    z-index: 1;
  }

  .text-highlight-tag {
    border-radius: 8px;
    padding: 1px 3px;
    margin: -1px -3px;
    overflow-wrap: break-word;

    &.bg-blue {
      background-color: lightblue;
    }

    &.bg-pink {
      background-color: lightcoral;
    }

    &.bg-yellow {
      background-color: #f1f500;
    }
  }
}
