@use '@angular/material' as mat;
@use '../theme';
@use '../palette';

@mixin typography($typography-config) {
  mat-vertical-stepper.sub-stepper .mat-step .mat-step-header .mat-step-label {
    @include mat.typography-level($typography-config, body-2);
  }
}

@mixin color($color-config) {
  $primary: map-get($color-config, primary);
  $radius: 0.5rem;

  mat-stepper.mat-stepper-horizontal {
    background: transparent;
  }

  .mat-step-header.mat-vertical-stepper-header:not([aria-disabled]):hover {
    border-radius: $radius;
  }

  .sub-stepper {
    .mat-step-header:has(.mat-step-label-selected) {
      border-radius: $radius;
      background-color: mat.get-color-from-palette($primary, deep-blue-50, 0.15);

      &:hover {
        border-radius: $radius;
        background-color: mat.get-color-from-palette($primary, deep-blue-50);
      }
    }
  }

  .mat-step-header.mat-vertical-stepper-header.mat-vertical-stepper-header {
    .mat-step-label {
      &.mat-step-label-selected {
        color: mat.get-contrast-color-from-palette($primary, deep-blue-8);
      }

      &:not(.mat-step-label-selected) {
        color: gray; // TODO: From theme
      }
    }
    .mat-step-icon:not(.mat-step-icon-selected):not(.mat-step-icon-state-edit):not(.mat-step-icon-state-completed) {
      border: 1px solid mat.get-contrast-color-from-palette($primary, deep-blue-8);
      background: transparent;
      color: mat.get-contrast-color-from-palette($primary, deep-blue-8);
    }
  }
}

@mixin density() {
  @include mat.stepper-density(minimum);
}

@mixin theme() {
  $color-config: mat.get-color-config(theme.$theme-config);
  $typography-config: mat.get-typography-config(theme.$theme-config);

  @include mat.stepper-theme(theme.$theme-config);
  @include color($color-config);
  @include typography($typography-config);
  @include density();
}
