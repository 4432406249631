$icon-size: (
  small: 16px,
  default: 24px,
);

$default-icon-size: map-get($icon-size, default);

@mixin size($icon-type) {
  $icon-size: map-get($icon-size, $icon-type);

  width: $icon-size;
  height: $icon-size;
  font-size: $icon-size;
  line-height: $icon-size;
}
