@use '@angular/material' as mat;
@use '../../../styles/theme';

@mixin hierarchy() {
  $typography-config: mat.get-typography-config(theme.$theme-config);
  $color-config: mat.get-color-config(theme.$theme-config);
  $primary: map-get($color-config, primary);

  @include mat.typography-hierarchy($typography-config);

  .mat-typography .mat-headline-1,
  .mat-headline-1,
  .mat-typography .mat-headline-2,
  .mat-headline-2,
  .mat-typography .mat-headline-3,
  .mat-headline-3,
  .mat-typography .mat-headline-4,
  .mat-headline-4,
  .mat-typography .mat-headline-5,
  .mat-headline-5,
  .mat-typography .mat-headline-6,
  .mat-headline-6,
  .mat-typography .mat-subtitle-1,
  .mat-subtitle-1,
  .mat-typography .mat-subtitle-2,
  .mat-subtitle-2,
  .mat-typography .mat-body-1,
  .mat-body-1,
  .mat-typography .mat-body-2,
  .mat-body-2 {
    margin-bottom: 0;
  }

  .link {
    color: mat.get-color-from-palette($primary, deep-blue-100);
    cursor: pointer;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  .clickable-link:hover {
    color: mat.get-color-from-palette($primary, deep-blue-100);
    cursor: pointer;
    text-decoration: underline;
  }
}
