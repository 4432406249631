/* You can add global styles to this file, and also import other style files */
@use 'all-components';

@import url('https://fonts.googleapis.com/icon?family=Material+Icons|Material+Icons+Outlined');
@import 'variables';
@import 'colorClasses';
@import 'spacing';
@import 'flex';
@import 'responsive';
@import 'topBar';
@import 'sharedClasses';
@import 'tab-theme';
@import 'show-more';
@import 'typography';

@include all-components.all-component-styles();

html {
  &.cdk-global-scrollblock {
    overflow: hidden;
  }
}

html,
body {
  height: 100%;
}

body {
  margin: 0 auto;
  background-color: white;
  font-family: SwissReSanRegu, Helvetica, sans-serif;
}

* {
  scrollbar-width: thin !important;
  font-family: SwissReSanRegu, Helvetica, sans-serif;
  font-weight: 400;
  font-style: normal;
}

.material-symbols-outlined-filled {
  font-variation-settings: 'FILL' 1, 'wght' 400, 'GRAD' 0, 'opsz' 48;
}

.p-datatable {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}

.mat-mdc-menu-panel {
  min-height: auto !important;
}

.open-in-new-icon {
  color: $primaryButtonInactiveColor;
  font-size: 12px !important;
  position: absolute;
  top: 0;
  right: 0;
}

.border-delimitation:hover {
  border: 1px solid $borderColor;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 16px;
  padding: 6px 0;
}

.inactive {
  color: $primaryButtonInactiveColor;
}
