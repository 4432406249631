@use 'sass:math';

@mixin styles() {
  $navbar-height: 76px;
  $drawer-spacing: 16px;

  mat-drawer-container.mat-drawer-container {
    height: calc(100% - ($navbar-height + $drawer-spacing));
    margin: $drawer-spacing 0 $drawer-spacing 0;
    display: flex;
    justify-content: center;

    mat-drawer {
      width: 300px;
    }

    .mat-drawer-content {
      max-width: 1920px;
      width: 100%;
      padding: 0 $drawer-spacing;
      overflow-x: auto;
      overflow-y: hidden;
    }
  }
}
