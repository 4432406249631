@mixin styles() {
  $spacing: 8px;

  mat-divider:not(.mcd-divider-vertical) {
    width: 100%;
    margin-bottom: $spacing;

    &.top-spacing {
      margin-top: $spacing;
    }
  }
}
