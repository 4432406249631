@use '@angular/material' as mat;
@use '@material/density';
@use '../theme';

@mixin color($color-config) {
  $foreground: map-get($color-config, foreground);
  $primary: map-get($color-config, primary);

  mat-expansion-panel.mat-expansion-panel {
    &.selected {
      border: 2px solid mat.get-color-from-palette($primary, default);
      background-color: mat.get-color-from-palette($primary, deep-blue-8);
    }

    &:hover:not(.no-hover) {
      background-color: mat.get-color-from-palette($primary, deep-blue-8);
    }

    border: 1px solid mat.get-color-from-palette($foreground, disabled);

    &:not(.mat-expanded) {
      mat-expansion-panel-header.mat-expansion-panel-header:not([aria-disabled='true']) {
        &:hover {
          background-color: mat.get-color-from-palette($primary, deep-blue-8);
        }
      }
    }

    mat-expansion-panel-header.mat-expansion-panel-header {
      span.mat-expansion-indicator::after {
        color: mat.get-color-from-palette($primary, default);
      }
    }
  }
}

@mixin density() {
  mat-expansion-panel.high-density {
    padding: 1rem;

    &:not(.mat-expanded) {
      max-height: 80px;
    }

    mat-expansion-panel-header {
      padding: 0 0.5rem;
    }
  }
}

@mixin theme() {
  @include mat.expansion-theme(theme.$theme-config);

  $color-config: mat.get-color-config(theme.$theme-config);

  @include color($color-config);
  @include density();

  mat-expansion-panel {
    &.mat-expansion-panel:not([class*='mat-elevation-z']) {
      @include mat.overridable-elevation(0);
    }
  }
}
