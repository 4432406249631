@mixin styles() {
  mat-dialog-container .mdc-dialog__container {
    border-radius: 4px;

    .mdc-dialog__actions {
      margin-top: auto;
    }

    .mat-mdc-dialog-surface {
      $spacing: 8px;
      $padding: 24px;

      .mat-mdc-dialog-title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-top: $padding;

        &::before {
          display: none;
        }

        > :not(:first-child) {
          margin-left: $spacing;
        }

        .right-section,
        .left-section {
          display: flex;
          gap: 1rem;
          align-items: center;
        }
      }

      dialog-sub-header {
        display: block;
        padding: 0 $padding;
      }

      mat-divider.mat-divider-horizontal {
        margin-top: $spacing;
      }

      .mat-mdc-dialog-content {
        > mat-tab-group.mat-mdc-tab-group {
          margin-top: -20px;
        }

        mat-expansion-panel mat-expansion-panel-header mat-panel-title {
          flex-grow: unset;
        }
      }
    }
  }
}
