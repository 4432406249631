.input-container {
  display: -ms-flexbox;
  /* IE10 */
  display: flex;
  width: 100%;
  margin-bottom: 15px;
}

.input-field:focus {
  border: 1px solid dodgerblue;
}

pre {
  white-space: pre-wrap;
  /* Since CSS 2.1 */
  white-space: -moz-pre-wrap;
  /* Mozilla, since 1999 */
  white-space: -pre-wrap;
  /* Opera 4-6 */
  white-space: -o-pre-wrap;
  /* Opera 7 */
  word-wrap: break-word;
}

body .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .ui-tree-toggler {
  vertical-align: middle;
  display: inline-block;
  float: none;
  margin: 0 0.143em 0 0;
  color: #848484;
  position: absolute !important;
  top: 5px !important;
  left: 5px !important;
}

body .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .ui-chkbox {
  margin: 0 0.5em 0 1.7em;
}

body .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content {
  position: relative !important;
}

.cdk-overlay-container {
  z-index: 4000;
}

.cdk-overlay-connected-position-bounding-box {
  z-index: 4000;
}

.cdk-overlay-container mat-datepicker-content {
  z-index: 4000 !important;
}

.mat-datepicker-popup {
  z-index: 4000 !important;
}

.right-tree {
  .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .ui-treenode-label {
    margin-left: 21px;
  }
}

.ui-dropdown {
  width: 100% !important;
  .ui-dropdown-label-container {
    width: 100% !important;
  }
}

.ui-dropdown-panel {
  z-index: 1200 !important;
}

input:disabled {
  background: #cecece;
  border: none;
  cursor: not-allowed;
}

.border-panel {
  border: 1px solid $borderColor;
}

.container-fluid {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.top,
.bottom {
  height: 60px;
}

.manageMenu {
  max-width: none !important;
  .mat-mdc-menu-content {
    width: auto !important;
  }
  ul {
    float: left;
    min-width: 10rem;
    margin: 0.125rem 0 0;
    font-size: 1rem;
    color: #212529;
    text-align: left;
    list-style: none;
    background-color: #fafafa;
    background-clip: padding-box;
    border-radius: 0.25rem;
    li {
      cursor: pointer;
    }
    a {
      display: block;
      width: 100%;
      padding: 8px 15px 8px 50px;
      clear: both;
      text-align: inherit;
      white-space: nowrap;
      background-color: transparent;
      border: 0;
      font-family: 'SwissReSans', Helvetica, sans-serif !important;
      font-style: normal !important;
      font-weight: 400 !important;
      font-size: 16px !important;
      line-height: 24px !important;
      letter-spacing: 0.15px !important;
      color: $SR_TextColor !important;
      text-decoration: blink;
    }
  }
}
