@use '@angular/material' as mat;
@use '../theme';

@mixin theme() {
  @include mat.select-theme(theme.$theme-config);

  .mat-primary mat-option.mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) > span.mdc-list-item__primary-text {
    color: inherit;
  }
}
