@import "./variables.scss";


a.mat-mdc-tab-link {
  padding-right: 0;
  padding-left: 0;
  margin-right: 32px;
  margin-left: 32px;
  min-width: 0;
}

.mat-mdc-tab-nav-bar,
.mat-mdc-tab-header {
  border-bottom: 1px solid;
  border-color: $SR_Black_900-12P;
}

.mdc-tab-indicator .mdc-tab-indicator__content--underline {
  border-top-width: 5px !important;
}

.subTabGroup {
  .mat-mdc-tab-labels {
    display: block !important;
    text-align: center;
  }
  .subTabTitle {
    //font-size: 12px !important;
    font-weight: 400 !important;
    line-height: 16px !important;
    letter-spacing: 0.4px;
  }
  .mat-mdc-tab.mdc-tab {
    margin: 15px 0;
    padding: 0;
    min-width: 100px;
    display: flex;
    align-items: flex-start;
    height: max-content;
  }
  .mat-mdc-tab-nav-bar,
  .mat-mdc-tab-header {
    border: none !important;
  }
  .mdc-tab-indicator {
    display: none;
  }
}

.mat-mdc-menu-content {
  background-color: #ffffff !important;
}

tbody {
  tr:last-child {
    td {
      border-bottom: 0 !important;
    }
  }
}
