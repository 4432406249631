@use '@angular/material' as mat;
@use '../theme';
@use '../palette';

@mixin theme() {
  @include mat.sidenav-theme(theme.$theme-config);

  mat-drawer-container.mat-drawer-container {
    background-color: palette.$light-primary-text;
  }
}
