@use '../icon/icon-base';

$button-icon-spacing: 5px;

@mixin styles() {
  button {
    &.mdc-button {
      text-transform: uppercase;

      &.mdc-button--outlined.mat-mdc-outlined-button.borderless {
        border: none;
      }

      .mat-icon,
      .material-icons,
      .material-icons-outlined {
        margin-right: $button-icon-spacing;
      }
    }

    &.small.mdc-button {
      $smallButtonHeight: 26px;
      height: $smallButtonHeight;

      > .mat-mdc-button-touch-target {
        height: $smallButtonHeight;
      }
    }

    &.mat-mdc-icon-button {
      @include icon-base.size(default);
      padding: 0;

      .mat-mdc-button-touch-target {
        @include icon-base.size(default);
      }
    }
  }
}
