@mixin styles() {
  mat-form-field {
    .mat-mdc-form-field-focus-overlay {
      background-color: unset !important;
    }

    .mat-mdc-form-field-icon-suffix {
      padding: 0 0.5rem 0 0.5rem;

      > .mat-icon {
        padding: 0;
      }
    }

    .mat-mdc-form-field-subscript-wrapper {
      .mat-mdc-form-field-hint-wrapper,
      .mat-mdc-form-field-error-wrapper {
        padding: 0;
      }
    }

    &.full-width {
      width: 100%;
    }

    &.half-width {
      width: 50%;
    }

    &.filter-form-field {
      min-width: 300px;
      max-width: 400px;
    }

    &.popover-form-field {
      min-width: 350px;
      max-width: 400px;
    }

    &.no-underline {
      .mdc-line-ripple {
        display: none;
      }
    }

    mat-hint {
      display: flex;
      align-items: center;
    }
  }
}
